/* latin-ext */
@font-face {
  font-family: '__Poppins_6c8d03';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/204a0a5e1cf12efb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_6c8d03';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2cc74b96cdc9383d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_6c8d03';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/bd907a34e79d0cf5-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_6c8d03';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/7c1535108079abc4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_6c8d03';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/8727a9eb9a9ed107-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_6c8d03';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/cf1f69a0c8aed54d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_6c8d03';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/dec290caeb2cbe42-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_6c8d03';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/f0496e21808c976b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_6c8d03';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fe0777f1195381cb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_6c8d03';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/eafabf029ad39a43-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_6c8d03';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c3bc380753a8436c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_6c8d03';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0484562807a97172-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_6c8d03';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a1386beebedccca4-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_6c8d03';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/b957ea75a84b6ea7-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_6c8d03';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/b8442747db2a9bad-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_6c8d03';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/6245472ced48d3be-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_6c8d03';src: local("Arial");ascent-override: 93.62%;descent-override: 31.21%;line-gap-override: 8.92%;size-adjust: 112.16%
}.__className_6c8d03 {font-family: '__Poppins_6c8d03', '__Poppins_Fallback_6c8d03'
}

/* src/global.css */
*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}
::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}
.\!pointer-events-auto {
  pointer-events: auto !important;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.-right-\[1px\] {
  right: -1px;
}
.-top-\[1px\] {
  top: -1px;
}
.left-0 {
  left: 0px;
}
.left-\[4px\] {
  left: 4px;
}
.top-0 {
  top: 0px;
}
.top-\[4px\] {
  top: 4px;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.box-border {
  box-sizing: border-box;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.h-16 {
  height: 4rem;
}
.h-\[120px\] {
  height: 120px;
}
.h-\[14px\] {
  height: 14px;
}
.h-\[18px\] {
  height: 18px;
}
.h-\[26px\] {
  height: 26px;
}
.h-\[32px\] {
  height: 32px;
}
.h-\[9px\] {
  height: 9px;
}
.h-px {
  height: 1px;
}
.max-h-\[500px\] {
  max-height: 500px;
}
.w-12 {
  width: 3rem;
}
.w-72 {
  width: 18rem;
}
.w-\[14px\] {
  width: 14px;
}
.w-\[18px\] {
  width: 18px;
}
.w-\[32px\] {
  width: 32px;
}
.w-\[376px\] {
  width: 376px;
}
.w-\[400px\] {
  width: 400px;
}
.w-\[9px\] {
  width: 9px;
}
.w-full {
  width: 100%;
}
.flex-1 {
  flex: 1 1;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.cursor-grab {
  cursor: -webkit-grab;
  cursor: grab;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-text {
  cursor: text;
}
.flex-col {
  flex-direction: column;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.gap-0 {
  gap: 0px;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-5 {
  gap: 1.25rem;
}
.self-stretch {
  align-self: stretch;
}
.overflow-y-auto {
  overflow-y: auto;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.whitespace-pre-wrap {
  white-space: pre-wrap;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-\[999px\] {
  border-radius: 999px;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-sm {
  border-radius: 0.125rem;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-bl {
  border-bottom-left-radius: 0.25rem;
}
.rounded-tl-xl {
  border-top-left-radius: 0.75rem;
}
.rounded-tr-xl {
  border-top-right-radius: 0.75rem;
}
.border {
  border-width: 1px;
}
.border-solid {
  border-style: solid;
}
.border-none {
  border-style: none;
}
.border-\[\#008E00\] {
  --tw-border-opacity: 1;
  border-color: rgb(0 142 0 / var(--tw-border-opacity, 1));
}
.border-\[\#AE1D20\] {
  --tw-border-opacity: 1;
  border-color: rgb(174 29 32 / var(--tw-border-opacity, 1));
}
.border-\[\#D9C415\] {
  --tw-border-opacity: 1;
  border-color: rgb(217 196 21 / var(--tw-border-opacity, 1));
}
.border-\[\#FFE619\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 230 25 / var(--tw-border-opacity, 1));
}
.border-st-grey-light {
  --tw-border-opacity: 1;
  border-color: rgb(58 59 65 / var(--tw-border-opacity, 1));
}
.bg-\[\#008E00\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 142 0 / var(--tw-bg-opacity, 1));
}
.bg-\[\#008e00\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 142 0 / var(--tw-bg-opacity, 1));
}
.bg-\[\#008e00\]\/10 {
  background-color: rgb(0 142 0 / 0.1);
}
.bg-\[\#091001\] {
  --tw-bg-opacity: 1;
  background-color: rgb(9 16 1 / var(--tw-bg-opacity, 1));
}
.bg-\[\#228BE6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(34 139 230 / var(--tw-bg-opacity, 1));
}
.bg-\[\#228BE6\]\/10 {
  background-color: rgb(34 139 230 / 0.1);
}
.bg-\[\#7c5cff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(124 92 255 / var(--tw-bg-opacity, 1));
}
.bg-\[\#7c5cff\]\/10 {
  background-color: rgb(124 92 255 / 0.1);
}
.bg-\[\#80867d\] {
  --tw-bg-opacity: 1;
  background-color: rgb(128 134 125 / var(--tw-bg-opacity, 1));
}
.bg-\[\#D9C415\] {
  --tw-bg-opacity: 1;
  background-color: rgb(217 196 21 / var(--tw-bg-opacity, 1));
}
.bg-\[\#FFE619\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 230 25 / var(--tw-bg-opacity, 1));
}
.bg-\[\#FFF2F1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 242 241 / var(--tw-bg-opacity, 1));
}
.bg-\[\#d7a3ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(215 163 255 / var(--tw-bg-opacity, 1));
}
.bg-\[\#ebfaeb\] {
  --tw-bg-opacity: 1;
  background-color: rgb(235 250 235 / var(--tw-bg-opacity, 1));
}
.bg-\[\#faeded\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 237 237 / var(--tw-bg-opacity, 1));
}
.bg-\[\#ffe619\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 230 25 / var(--tw-bg-opacity, 1));
}
.bg-st-grey-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(9 16 1 / var(--tw-bg-opacity, 1));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.bg-white\/20 {
  background-color: rgb(255 255 255 / 0.2);
}
.p-0 {
  padding: 0px;
}
.p-2 {
  padding: 0.5rem;
}
.p-2\.5 {
  padding: 0.625rem;
}
.p-3 {
  padding: 0.75rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.pb-3 {
  padding-bottom: 0.75rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.font-sans {
  font-family:
    ui-sans-serif,
    system-ui,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.font-normal {
  font-weight: 400;
}
.font-semibold {
  font-weight: 600;
}
.text-\[\#DC2626\] {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity, 1));
}
.text-\[\#ffe619\] {
  --tw-text-opacity: 1;
  color: rgb(255 230 25 / var(--tw-text-opacity, 1));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.no-underline {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-90 {
  opacity: 0.9;
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow:
    var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.outline {
  outline-style: solid;
}
.outline-dashed {
  outline-style: dashed;
}
.outline-2 {
  outline-width: 2px;
}
.outline-\[\#008e00\] {
  outline-color: #008e00;
}
.outline-\[\#228BE6\] {
  outline-color: #228BE6;
}
.outline-\[\#7c5cff\] {
  outline-color: #7c5cff;
}
.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.stringtale-header-border {
  border-bottom-width: 1px;
  border-color: rgb(255 255 255 / 0.2);
  border-bottom-style: solid;
}
.hover\:border:hover {
  border-width: 1px;
}
.hover\:border-\[\#008E00\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(0 142 0 / var(--tw-border-opacity, 1));
}
.hover\:border-\[\#D9C415\]:hover {
  --tw-border-opacity: 1;
  border-color: rgb(217 196 21 / var(--tw-border-opacity, 1));
}
.hover\:border-white:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}
.hover\:bg-\[\#008E00\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 142 0 / var(--tw-bg-opacity, 1));
}
.hover\:bg-\[\#373A40\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 58 64 / var(--tw-bg-opacity, 1));
}
.hover\:bg-\[\#F1D917\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 217 23 / var(--tw-bg-opacity, 1));
}
.active\:translate-y-px:active {
  --tw-translate-y: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.active\:transform:active {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.active\:bg-\[\#3a4034\]:active {
  --tw-bg-opacity: 1;
  background-color: rgb(58 64 52 / var(--tw-bg-opacity, 1));
}
.disabled\:border-\[\#C7C7C7\]:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(199 199 199 / var(--tw-border-opacity, 1));
}
.disabled\:bg-\[\#C7C7C7\]:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(199 199 199 / var(--tw-bg-opacity, 1));
}


html, body, #root {
  margin: 0;
  padding: 0;
}


h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

button {
  font-family: inherit;
}
